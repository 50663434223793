{
  "name": "frontend-app",
  "version": "0.1.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:frontend-app": "node dist/frontend-app/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.2",
    "@angular/common": "^18.1.2",
    "@angular/compiler": "^18.1.2",
    "@angular/core": "^18.1.2",
    "@angular/forms": "^18.1.2",
    "@angular/platform-browser": "^18.1.2",
    "@angular/platform-browser-dynamic": "^18.1.2",
    "@angular/platform-server": "^18.1.2",
    "@angular/router": "^18.1.2",
    "@angular/ssr": "^18.1.2",
    "@ng-select/ng-select": "^13.5.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@ton-api/client": "^0.2.0",
    "@ton/core": "^0.57.0",
    "@ton/crypto": "^3.3.0",
    "@ton/ton": "^13.11.2",
    "@tonconnect/ui": "^2.0.9",
    "buffer": "^6.0.3",
    "chart.js": "^4.4.4",
    "chartjs-adapter-moment": "^1.0.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "chartjs-plugin-zoom": "^2.0.1",
    "csv-parse": "^5.5.6",
    "dayjs": "^1.11.10",
    "ethers": "^5.7.2",
    "express": "^4.18.2",
    "moment": "^2.30.1",
    "ng2-charts": "^6.0.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.2",
    "@angular/cli": "^18.1.2",
    "@angular/compiler-cli": "^18.1.2",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "prettier": "^3.2.5",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.5.4"
  }
}
